<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="ban_opera">
      <div class="phone_search">
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px; margin-right: 10px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input placeholder="手机号查询" v-model="phone"></el-input>
        <el-select v-model="sort" placeholder="查询封禁时间顺序">
          <el-option label="封禁时间正序" value="start_time ASC"></el-option>
          <el-option label="封禁时间倒序" value="start_time DESC"></el-option>
          <el-option label="累计封禁次数正序" value="count ASC"></el-option>
          <el-option label="累计封禁次数倒序" value="count DESC"></el-option>
        </el-select>
        <el-button type="primary" @click="searchBan">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>

      <div class="ban_dialog">
        <el-button type="primary" @click="dialogFormVisible = true"
          >添加封禁用户</el-button
        >

        <el-dialog title="添加封禁用户" :visible.sync="dialogFormVisible">
          <el-form :model="form" label-width="80px" :rules="rules">
            <el-form-item label="区号" prop="phoneArea"
              ><el-select
                v-model="form.phoneArea"
                filterable
                clearable
                placeholder="请选择区号(可搜索关键字)"
                style="width: 240px"
                v-if="internationType"
              >
                <el-option
                  v-for="item in options"
                  :key="item.name"
                  :label="item.name + item.tel"
                  :value="item.tel"
                >
                </el-option> </el-select
            ></el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入封禁的手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="封禁原因">
              <el-input
                v-model="form.reason"
                placeholder="请输入封禁原因"
              ></el-input>
            </el-form-item>
            <el-form-item label="封禁模块">
              <el-select v-model="form.module" placeholder="请选择封禁模块">
                <el-option label="余额提现" value="2"></el-option>
                <el-option label="余额充值" value="3"></el-option>
                <el-option label="积分上链" value="4"></el-option>
                <el-option label="转出额度" value="5"></el-option>
                <el-option
                  label="CPLE积分-额度互兑大转盘"
                  value="6"
                ></el-option>
                <el-option label="领取回馈" value="7"></el-option>
                <el-option label="特惠商城" value="8"></el-option>
                <el-option label="任务" value="9"></el-option>
                <el-option label="发布车主行程" value="10"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="封禁周期">
              <el-select v-model="form.date" placeholder="请选择封禁周期">
                <el-option label="7天" value="7"></el-option>
                <el-option label="14天" value="14"></el-option>
                <el-option label="30天" value="30"></el-option>
                <el-option label="90天" value="90"></el-option>
                <el-option label="永久" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleAddBan">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phoneArea" label="区号" width="80">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="180">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="130">
        </el-table-column>
        <el-table-column prop="banType" label="封禁模块" width="130">
          <template slot="header" slot-scope="scope">
            <el-select
              v-model="banModule"
              @change="handleChangeBanModule"
              placeholder="封禁模块"
            >
              <el-option label="全部" value="0"></el-option>
              <el-option label="余额提现" value="2"></el-option>
              <el-option label="余额充值" value="3"></el-option>
              <el-option label="积分上链" value="4"></el-option>
              <el-option label="转出额度" value="5"></el-option>
              <el-option label="CPLE积分-额度互兑大转盘" value="6"></el-option>
              <el-option label="领取回馈" value="7"></el-option>
              <el-option label="特惠商城" value="8"></el-option>
              <el-option label="任务" value="9"></el-option>
              <el-option label="发布车主行程" value="10"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="reason" label="原因" width="180">
        </el-table-column>
        <el-table-column prop="times" label="封禁周期" width="130">
          <template slot="header" slot-scope="scope">
            <el-select
              v-model="cycle"
              placeholder="封禁周期"
              @change="handleChangeBanModule"
            >
              <el-option label="全部" value="0"></el-option>
              <el-option label="7天" value="7"></el-option>
              <el-option label="14天" value="14"></el-option>
              <el-option label="30天" value="30"></el-option>
              <el-option label="90天" value="90"></el-option>
              <el-option label="永久" value="1"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="封禁时间" width="180">
        </el-table-column>
        <el-table-column prop="endTime" label="解禁时间" width="180">
        </el-table-column>
        <el-table-column prop="releaseTime" label="实际解禁时间" width="180">
        </el-table-column>
        <el-table-column prop="banneder" label="封禁人" width="130">
        </el-table-column>
        <el-table-column prop="releaser" label="解禁人" width="130">
        </el-table-column>
        <el-table-column prop="banStatus" label="状态" width="130">
          <template slot="header" slot-scope="scope">
            <el-select
              v-model="banStatus"
              placeholder="封禁状态"
              @change="handleChangeBanModule"
            >
              <el-option label="全部" value="0"></el-option>
              <el-option label="封禁中" value="1"></el-option>
              <el-option label="人工解禁" value="-1"></el-option>
              <el-option label="自动解禁" value="-2"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="累计封禁次数" width="130">
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
              v-if="scope.row.banStatus == '封禁中'"
              >解禁</el-button
            >
            <el-button
              @click="handleClick(scope.row)"
              type="text"
              size="small"
              v-else-if="scope.row.banStatus != '封禁中'"
              style="color: #ccc"
              >已解禁</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    let validatePhone = (rule, value, callback) => {
      //使用正则表达式进行验证手机号码
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号不正确！"));
      }
    };
    return {
      abc: "",
      cycle: "", // 封禁周期
      banStatus: "", // 封禁状态
      banModule: "", // 封禁模块
      valueArea: "",
      options: [],
      phone: "", // 手机号
      sort: "", // 排序搜索
      tableData: [],
      dialogFormVisible: false,
      form: {
        phone: "", // 手机号
        phoneArea: "",
        reason: "", // 封禁原因
        module: "", // 封禁模块
        date: "", // 封禁周期
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },

      rules: {
        phone: [
          { validator: validatePhone, trigger: "blur" },
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        phoneArea: [{ required: true, message: "请选择区号", trigger: "blur" }],
      },

      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "用户封禁管理",
        },
        {
          name: "其他封禁",
        },
      ], // 面包屑数据

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getBanRecordList,
      method: "POST",
      params: JSON.stringify({
        phone: "",
        phoneArea: this.phoneAreaROLE,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          res.data.data.list.map((item) => {
            this.changeItem(item);
          });
          hideLoading();
        }
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    // 搜索
    searchBan() {
      if (this.phone || this.sort || this.valueArea) {
        showLoading();
        const opt = {
          url: reqUrl.getBanRecordList,
          method: "POST",
          params: JSON.stringify({
            orderBy: this.sort,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              res.data.data.list.map((item) => {
                this.changeItem(item);
              });
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "error");

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    // 分页
    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getBanRecordList,
        method: "POST",
        params: JSON.stringify({
          orderBy: this.sort,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          status: this.banStatus,
          times: this.cycle,
          type: this.banModule,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;

            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    // 添加封禁用户
    handleAddBan() {
      const opt = {
        url: reqUrl.userPartBan,
        method: "POST",
        params: JSON.stringify({
          phone: this.form.phone,
          phoneArea: this.form.phoneArea || this.phoneAreaROLE,
          reason: this.form.reason,
          times: this.form.date,
          type: this.form.module,
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else {
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.dialogFormVisible = false;
            this.refresh();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 重置
    reset() {
      if (this.phone || this.sort) {
        this.phone = "";
        this.sort = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 解禁
    handleClick(row) {
      this.$confirm("你确定要解禁该用户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.banType == "余额提现") {
            row.banType = 2;
          } else if (row.banType == "余额充值") {
            row.banType = 3;
          } else if (row.banType == "积分上链") {
            row.banType = 4;
          } else if (row.banType == "转出额度") {
            row.banType = 5;
          } else if (row.banType == "CPLE积分-额度互兑大转盘") {
            row.banType = 6;
          } else if (row.banType == "领取回馈") {
            row.banType = 7;
          } else if (row.banType == "特惠商城") {
            row.banType = 8;
          } else if (row.banType == "任务") {
            row.banType = 9;
          } else if (row.banType == "发布车主行程") {
            row.banType = 10;
          }

          const opt = {
            url: reqUrl.manualRelease,
            method: "POST",
            params: JSON.stringify({
              id: row.id,
              userId: row.userId,
              banType: row.banType,
            }),
            resFunc: (res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "解禁成功!",
                });
                this.refresh();
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解禁",
          });
        });
    },

    // 过滤表格所选择项
    handleChangeBanModule() {
      if (this.banModule == "0") {
        this.banModule = "";
      }
      if (this.banStatus == "0") {
        this.banStatus = "";
      }
      if (this.cycle == "0") {
        this.cycle = "";
      }
      const opt = {
        url: reqUrl.getBanRecordList,
        method: "POST",
        params: JSON.stringify({
          orderBy: this.sort,
          phone: "",
          phoneArea: this.phoneAreaROLE,
          status: this.banStatus,
          times: this.cycle,
          type: this.banModule,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 刷新
    refresh() {
      this.reload();
    },

    changeItem(item) {
      if (item.times == 1) {
        item.times = "永久";
      } else if (item.times == 7) {
        item.times = "7天";
      } else if (item.times == 14) {
        item.times = "14天";
      } else if (item.times == 30) {
        item.times = "30天";
      } else if (item.times == 90) {
        item.times = "90天";
      }

      if (item.banStatus == 1) {
        item.banStatus = "封禁中";
      } else if (item.banStatus == -1) {
        item.banStatus = "人工解禁";
      } else if (item.banStatus == -2) {
        item.banStatus = "自动解禁";
      }

      if (item.banType == 2) {
        item.banType = "余额提现";
      } else if (item.banType == 3) {
        item.banType = "余额充值";
      } else if (item.banType == 4) {
        item.banType = "积分上链";
      } else if (item.banType == 5) {
        item.banType = "转出额度";
      } else if (item.banType == 6) {
        item.banType = "CPLE积分-额度互兑大转盘";
      } else if (item.banType == 7) {
        item.banType = "领取回馈";
      } else if (item.banType == 8) {
        item.banType = "特惠商城";
      } else if (item.banType == 9) {
        item.banType = "任务";
      } else if (item.banType == 10) {
        item.banType = "发布车主行程";
      }

      item.startTime = format_time_date(item.startTime);
      item.endTime = format_time_date(item.endTime);
      item.releaseTime = format_time_date(item.releaseTime);
    },

    // table index 第二页不递增问题
    count(index) {
      return (this.page.page - 1) * this.page.pagesize + index + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.ban_opera {
  display: flex;
  justify-content: space-between;
  .phone_search {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-input {
      width: 250px;
      margin-right: 10px;
    }

    .el-select {
      width: 200px;
      margin-right: 10px;
    }
  }

  ::v-deep .el-dialog {
    width: 40%;

    .el-select {
      width: 100%;
    }
  }
}
</style>
